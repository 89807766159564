import React, { useState, useEffect, useContext } from "react";
import axiosInstance from "../../axiosInstance";
// import API from "../../api";
import Filter from "./Filter";
import ActionCellMenu from "../../Components/ActionCellMenu";
import { AppContext } from "../../Context/AppContext";

import { CircularProgress, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { DataGrid } from "@mui/x-data-grid/DataGrid/DataGrid";
import { DeleteOutlined as DeleteOutlinedIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import RemoveDialog from "../../Components/RemoveDialog";
import DateBody from "../../Components/Columns/DateBody";
import { addCommas } from "../../lib";
import LinkBody from "../../Components/Columns/LinkBody";

function Payments({ permissions }) {
  const navigate = useNavigate();
  const { setShowBackButton } = useContext(AppContext);
  const [DataTable, setDataTable] = useState([]);
  const [filterValues, setFilterValues] = useState({
    paymentNumber: undefined,
    orderNumber: undefined,
    customerId: undefined,
  });
  const [FilterData, setFilterData] = useState({
    payments: [],
    orders: [],
    customers: [],
  });
  const [OpenRemove, setOpenRemove] = useState(false);
  const [RowData, setRowData] = useState({});
  const [Refresh, setRefresh] = useState(false);
  const [pageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [Loading, setLoading] = useState(false);
  useEffect(() => {
    setShowBackButton(false);
  }, []);
  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get("/payments/getAll", {
        params: {
          paymentNumber: filterValues?.paymentNumber,
          orderNumber: filterValues?.orderNumber,
          customerId: filterValues?.customerId?._id || undefined,
        },
      })
      .then((res) => {
        let _data = res?.data?.payments;
        // ?.map((ele) => [...(ele?.payments || [])])
        // .flat();
        // console.log(_data);
        setTotalRecords(_data?.length);
        let data = _data?.map((val, i) => ({
          ...val,
          customerName: val?.customerId?.name || "",
          id: i + 1,
        }));
        setPage(0);
        setDataTable([...data]);
        setLoading(false);
      })
      .catch((err) => {
        setTotalRecords(0);
        setDataTable([]);
        setLoading(false);
      });
  }, [filterValues, Refresh]);
  useEffect(() => {
    axiosInstance.get("/payments/getAll").then((res) => {
      let data = res?.data?.payments;
      let customersIds = [];
      let customers = [];
      data?.forEach((ele) => {
        if (!customersIds?.includes(ele?.customerId?._id)) {
          customersIds = [...customersIds, ele?.customerId?._id];
          customers = [...customers, ele?.customerId];
        }
      });
      setFilterData({
        payments: [...new Set(data?.map((ele) => ele?.paymentNumber))],
        orders: [...new Set(data?.map((ele) => ele?.orderNumber))],
        customers: customers,
      });
    });
  }, [Refresh]);
  const columns = [
    {
      field: "paymentNumber",
      headerName: "Payment",
      align: "left",
      headerAlign: "left",
      flex: 1,
      hideSortIcons: false,
      disableColumnMenu: true,
      sortable: true,
      // editable: true,
      //renderEditCell: (params) => <CustomTypeEditComponent {...params} />,
    },
    {
      field: "orderNumber",
      headerName: "Order No",
      align: "left",
      headerAlign: "left",
      flex: 1,
      hideSortIcons: false,
      disableColumnMenu: true,
      sortable: true,
      renderCell: (params) => (
        <LinkBody
          value={params?.row?.orderNumber}
          to={`/orders/view/${params?.row?.orderId?._id}`}
        />
      ),
    },
    {
      field: "paymentMode",
      headerName: "Payment Mode",
      align: "left",
      headerAlign: "left",
      flex: 1,
      hideSortIcons: false,
      disableColumnMenu: true,
      sortable: true,
      editable: true,
    },
    {
      field: "transactionId",
      headerName: "Transaction Id",
      align: "left",
      headerAlign: "left",
      flex: 1,
      hideSortIcons: false,
      disableColumnMenu: true,
      sortable: true,
      editable: true,
    },
    {
      field: "customerName",
      headerName: "Customer",
      align: "left",
      headerAlign: "left",
      flex: 1,
      hideSortIcons: false,
      disableColumnMenu: true,
      sortable: true,
      editable: true,
      // renderCell: (params) => AllCustomers[params?.row?.customerId] || "",
    },
    {
      field: "amountReceived",
      headerName: "Amount",
      align: "left",
      headerAlign: "left",
      flex: 1,
      hideSortIcons: false,
      disableColumnMenu: true,
      sortable: true,
      renderCell: (params) => (
        <Stack
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          spacing={1}
          sx={{ height: "100%", color: "#000" }}
        >
          <Typography variant="subtitle2">
            CAD {addCommas(params?.row?.amountReceived)}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "paymentDate",
      headerName: "Payment Date",
      align: "left",
      headerAlign: "left",
      flex: 1,
      hideSortIcons: false,
      disableColumnMenu: true,
      sortable: true,
      editable: true,
      renderCell: (params) =>
        params?.row?.paymentDate ? (
          <DateBody value={params?.row?.paymentDate} />
        ) : (
          ""
        ),
    },
    {
      field: "",
      headerName: "Actions",
      align: "left",
      headerAlign: "left",
      flex: 1,
      hideSortIcons: true,
      disableColumnMenu: true,
      disableExport: true,
      sortable: false,
      renderCell: (params) => (
        <>
          {!permissions?.payment?.delete ? (
            <small>Not authorized</small>
          ) : (
            <ActionCellMenu
              menuItems={[
                {
                  icon: <DeleteOutlinedIcon color="secondary" />,
                  label: "Delete",
                  color: "secondary",
                  onClick: () => {
                    setRowData(params?.row);
                    setOpenRemove(true);
                  },
                  hide: !permissions?.payment?.delete,
                },
              ]}
            />
          )}
        </>
      ),
    },
  ];
  return (
    <>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          <Filter
            setFilterValues={setFilterValues}
            filterValues={filterValues}
            showCreateBtn={false}
            FilterData={FilterData}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
          {Loading ? (
            <CircularProgress />
          ) : (
            <DataGrid
              rows={DataTable || []}
              columns={columns}
              style={{ overflow: "auto" }}
              pagination
              pageSize={pageSize}
              rowLength={totalRecords}
              pageSizeOptions={[5, 10, 25]}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: pageSize, page: page },
                },
              }}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "odd" : "even"
              }
              sx={{
                borderColor: "#fff",
                color: "#201D23CC",
                lineHeight: "19.6px",
                fontSize: "14px",
                fontFamily: "Montserrat",
                "& .even": {
                  backgroundColor: "#FAFAFA !important",
                },
                "& .odd": {
                  backgroundColor: "#FFF !important",
                },
                "& .MuiDataGrid-cell": {
                  border: "unset",
                  fontFamily: "Montserrat",
                },
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: "#FAFAFA !important",
                  borderBottom: "unset !important",
                },
                "& .MuiTablePagination-spacer": {
                  flex: "0 !important",
                },
                "& .MuiInputBase-root": {
                  marginRight: "auto !important",
                },
              }}
              disableRowSelectionOnClick
            />
          )}
        </Grid>
      </Grid>
      <RemoveDialog
        open={OpenRemove}
        setOpen={setOpenRemove}
        endpoint={"/payments"}
        itemId={RowData?.paymentNumber}
        setRefresh={setRefresh}
        Refresh={Refresh}
      />
    </>
  );
}
export default Payments;
